import React from 'react';

interface CornerProps {
  size: number; // px
  rotation?: number; // deg
}

const Corner = ({ size, rotation }: CornerProps) => (
  <div>
    <svg
      height={size}
      width={size}
      style={{ transform: `rotate(${rotation}deg)` }}
      viewBox="0 0 143.3 143.3"
    >

      <g transform="matrix(1.3333333,0,0,-1.3333333,-819.337,2073.6664)">
        <g transform="matrix(0.1,0,0,0.1,190.63911,-70.514561)">
          <path d="m 4831.6063,15664.717 c -31.4,-31.4 -82.56,-31.4 -114,0 -31.43,31.4 -31.39,82.6 0,113.9 31.44,31.4 82.53,31.4 113.96,0 31.43,-31.4 31.43,-82.5 0.04,-113.9 z m -190.98,101.7 c -11.07,-10.7 -31.31,-26 -64.66,-38.2 -35.88,-13.1 -76.38,-3.1 -99.96,5.3 12.71,21.5 37.3,55.2 73.18,68.2 3.85,1.4 7.59,2.7 11.27,3.7 19.79,-14.6 40.43,-25.9 59.82,-33 0.04,-0.1 0.14,-0.1 0.27,-0.1 6.78,-2.6 13.6,-4.5 20.08,-5.9 z m 89.43,89.4 c -5.5,25.5 -18.95,52.9 -39.22,80.2 1.04,3.6 2.29,7.3 3.71,11.1 13.01,35.8 46.71,60.5 68.29,73.1 8.33,-23.5 18.31,-64 5.27,-99.9 -12.11,-33.2 -27.43,-53.4 -38.05,-64.5 z m -56.08,26.1 c 9.27,-17.5 14.77,-34.5 15.44,-48 0.34,-5.1 -0.07,-9.5 -1.22,-13.2 -0.97,-3.4 -2.45,-6.3 -4.41,-8.2 -1.92,-2 -4.72,-3.4 -8.3,-4.5 -3.67,-1 -8.03,-1.4 -13.12,-1.2 -8.29,0.4 -17.33,2.4 -27.11,5.9 -0.1,0.1 -0.17,0.1 -0.24,0.1 -6.61,2.5 -13.66,5.7 -20.9,9.5 -13.97,7.5 -28.1,17.2 -41.97,29 -6.06,5.1 -11.8,10.4 -17.12,15.7 -35.68,35.7 -45.26,89.8 -47.66,121.9 17.47,-1.3 41.85,-4.8 66.34,-13.7 22.6,-8.2 41.28,-19.6 55.54,-33.9 5.3,-5.3 10.46,-11 15.79,-17.2 11.7,-14 21.45,-28.1 28.94,-42.2 z m 187.61,-247.2 c 47.92,47.9 47.95,126 0,174 -23.44,23.4 -54.09,35.3 -84.92,35.8 12.41,17.2 23.4,37.7 31.93,61.1 26.31,72.4 -14.13,151.2 -15.81,154.4 -0.44,0.9 -0.95,1.7 -1.52,2.5 -5.87,8.3 -16.63,11.3 -25.83,7.5 -3.17,-1.3 -76.59,-31.9 -106.81,-97.2 -18.58,18.3 -42.22,32.7 -70.18,42.9 -52,18.9 -102.09,17 -104.18,16.9 -11.23,-0.5 -20.06,-9.4 -20.57,-20.6 -0.24,-4.4 -4.18,-109.1 59.73,-174.3 -65.23,-30.3 -95.82,-103.7 -97.14,-106.9 -3.36,-8 -1.48,-17.2 4.66,-23.4 0.91,-0.9 1.82,-1.7 2.84,-2.4 0.8,-0.6 1.62,-1.1 2.43,-1.5 3.3,-1.7 82.05,-42.2 154.52,-15.8 23.27,8.5 43.88,19.5 61.01,31.9 0.54,-30.8 12.41,-61.5 35.85,-84.9 47.99,-48 126.06,-47.9 173.99,0" />
        </g>
      </g>
      <path d="m 143.35301,68.810837 -42.00634,0.173334 c -1.488005,0 -2.693335,1.2 -2.693335,2.693333 0,1.493333 1.20533,2.693333 2.693335,2.693333 l 41.98366,0.16" />
      <path d="m 74.530837,143.35301 -0.173334,-42.00634 c 0,-1.488005 -1.2,-2.693335 -2.693333,-2.693335 -1.493333,0 -2.693333,1.20533 -2.693333,2.693335 l -0.159999,41.98366" />
    </svg>
  </div>
);

export default Corner;
