import React, { Suspense } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import { PrimeReactProvider } from 'primereact/api';
import Home from './screens/Home';
import PieMenu from './screens/PieMenu';
import Winfinity from './screens/Winfinity';

import 'primereact/resources/themes/tailwind-light/theme.css';
import 'primereact/resources/primereact.css';

const { PUBLIC_URL } = process.env;

export default function App() {
  return (
    <PrimeReactProvider>
      <BrowserRouter basename={`/${PUBLIC_URL}`}>
        <Suspense fallback={<Home />}>
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="pies" element={<PieMenu />} />
            <Route path="winfinity" element={<Winfinity />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </PrimeReactProvider>
  );
}
