import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import FancyBorder from '../components/FancyBorder';
import Bullet from '../icons/Bullet';
import Pie from '../icons/Pie';
import Lattice from '../icons/Lattice';

interface MenuRowProps {
  row: MenuItem;
}

type MenuItem = {
  type: string,
  canIncludeRhubarb?: boolean,
  hasTopCrust?: boolean,
};

const MenuRow = ({ row }: MenuRowProps) => (
  <div className="flex flex-row flex-wrap space-y-2 items-center">
    <div className='h-6 w-12 md:h-8 mt-1'><Bullet /></div>
    <div className="w-32 md:w-40 flex items-center">{row.type}</div>
    <div className="flex ml-12 space-x-8">
      <div className="w-16 md:w-24">
        {row.hasTopCrust && (
        <div className="flex flex-row items-center text-lg text-gray-600 space-x-2">
          <div className="w-8 md:w-24"><Pie /></div>
          <span>/</span>
          <div className="w-8 md:w-24"><Lattice /></div>
        </div>
        )}
      </div>
      <div className="w-28 flex items-center justify-center text-base md:text-xl text-gray-600">
        {row.canIncludeRhubarb && <span>+ Rhubarb</span>}
      </div>
    </div>
  </div>
);

const menuItems: Array<MenuItem> = [
  {
    type: 'Apple',
    hasTopCrust: true,
  },
  {
    type: 'Blueberry',
    hasTopCrust: true,
  },
  {
    type: 'Cherry',
    hasTopCrust: true,
    canIncludeRhubarb: true,
  },
  {
    type: 'Key Lime',
  },
  {
    type: 'Peach',
    hasTopCrust: true,
    canIncludeRhubarb: true,
  },
  {
    type: 'Pumpkin',
  },
  {
    type: 'Strawberry',
    hasTopCrust: true,
    canIncludeRhubarb: true,
  },
];

const getBorderWidth = () => {
  const viewWidth = window.innerWidth;
  if (viewWidth > 768) {
    return 60;
  }
  return 30;
};

const PieMenu = () => {
  const [borderWidth, setBorderWidth] = useState(getBorderWidth());

  useEffect(() => {
    const refreshBorderWidth = () => {
      setBorderWidth(getBorderWidth());
    };

    window.addEventListener('resize', refreshBorderWidth);
    return () => {
      window.removeEventListener('resize', refreshBorderWidth);
    };
  }, []);

  return (
    <div className="flex w-full h-screen justify-center akaylaScript text-2xl md:text-4xl p-2">
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <div className="flex w-full h-full md:w-[48rem] md:h-[48rem] md:mt-28" >
        <FancyBorder width={borderWidth}>
          <div className="flex flex-col space-y-4 h-full mt-4">
            <div className="flex flex-col items-center">
              <span className="text-4xl md:text-6xl">Thanksgiving Pie Menu</span>
              <span className="text-5xl md:text-7xl lieselotte">28 November 2024</span>
            </div>
            <div className="flex flex-col space-y-4">
              {menuItems.map((item, idx) => (
                <MenuRow key={idx} row={item} />
              ))}
            </div>
          </div>
        </FancyBorder>
      </div>
    </div>
  );
};

export default PieMenu;
