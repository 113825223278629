import React from 'react';

const Bullet = () => (
  <svg
    style={{ height: '100%', width: '100%' }}
    viewBox="0 0 15 15"
  >
  <path
    d="m 9.2278376,7.7535529 -3.6884502,2.1295277 -3.68845,2.1295274 0,-4.2590552 0,-4.2590552 3.6884502,2.1295277 z"
    transform="matrix(1.1444664,0,0,1,0.13266438,-0.25355291)"
  />
  <path
    d="M 9.5918431,7.747499 6.0839575,9.7727777 2.576072,11.798056 l 0,-4.0505571 0,-4.0505573 3.5078857,2.0252788 z"
    style={{
      fill: 'none',
      fillOpacity: 1,
      stroke: '#000000',
      strokeWidth: 0.5,
      strokeMiterlimit: 4,
      strokeDasharray: 'none',
      strokeOpacity: 1,
    }}
    transform="matrix(1.0064945,0,0,0.91578269,3.4522874,0.40497453)"
    />
  </svg>
);

export default Bullet;
