import React from 'react';

const Lattice = () => (
  <svg viewBox="0 0 25 15">
    <g transform="translate(0,-6.1429433)">
      <path d="M 12.5,20 C 9,20 6,19.1 4.7,17.7 v 0 L 2.6,15.2 c -0.1,-0.1 -0.1,-0.2 0,-0.3 0.1,-0.1 0.2,-0.1 0.3,0 L 5,17.4 c 1.3,1.4 4.1,2.2 7.5,2.2 3.3,0 6.4,-0.9 7.5,-2.2 l 2.7,-3.6 c 0.1,-0.1 0.2,-0.1 0.3,0 0.1,0.1 0.1,0.2 0,0.3 l -2.7,3.6 v 0 C 19.1,19.1 15.9,20 12.5,20 Z" />
      <path d="m 3.1,12.9 v 0 C 2.9,12.8 2.9,12.7 2.9,12.6 3.2,11.9 3.5,11.4 4,11 4.9,9.6 5.4,8.9 6.2,8.4 7.4,7.6 8.8,7.7 9.8,7.8 c 1.3,-0.4 2.3,-0.2 2.8,0 0.1,0 0.2,0.2 0.1,0.3 0,0.1 -0.2,0.2 -0.3,0.1 C 12,7.9 11.1,7.7 9.9,8.1 H 9.8 C 8.3,8 7.3,8.1 6.4,8.7 5.7,9.2 5.2,9.8 4.3,11.2 c 0,0 0,0 0,0.1 -0.5,0.4 -0.8,0.8 -1,1.4 0,0.1 -0.1,0.2 -0.2,0.2 z" />
      <path d="m 3.6,14.9 c -0.1,0 -0.1,0 0,0 -1,-0.3 -1.8,-0.7 -2.4,-1.2 -0.5,-0.5 -0.8,-1.1 -0.8,-1.6 0,-0.4 0.2,-0.7 0.6,-1 0.5,-0.3 1.1,-0.3 1.7,0 0.1,0.1 0.1,0.2 0.1,0.3 -0.1,0.1 -0.2,0.1 -0.3,0.1 -0.4,-0.2 -0.9,-0.3 -1.3,0 -0.2,0.1 -0.4,0.3 -0.4,0.6 0,0.8 0.9,1.8 2.8,2.4 0.1,0 0.2,0.2 0,0.4 0.1,0 0,0 0,0 z" />
      <path d="m 6,15.2 v 0 C 5.8,15.1 5.8,15 5.8,14.9 6,14.5 6,14.1 5.8,13.8 5.2,13 3.6,12.8 2.7,12.9 c -0.1,0 -0.2,-0.1 -0.2,-0.2 0,-0.1 0.1,-0.2 0.2,-0.2 1,-0.1 2.8,0.2 3.5,1.1 0.2,0.4 0.2,0.9 0,1.4 0,0.1 -0.1,0.2 -0.2,0.2 z" />
      <path d="m 6,16.4 c -2.1,0 -3.7,-1 -4.1,-2.2 0,-0.1 0,-0.2 0.1,-0.3 0.1,0 0.2,0 0.3,0.1 0.2,0.7 0.9,1.3 1.9,1.6 1.1,0.4 2.4,0.4 3.7,0 0.1,0 0.2,0 0.3,0.1 0,0.1 0,0.2 -0.1,0.3 -0.8,0.3 -1.5,0.4 -2.1,0.4 z" />
      <path d="M 9.6,16 C 9.5,16 9.4,15.9 9.4,15.8 9.2,14.7 8.2,14.3 6.3,14.4 6.2,14.4 6.1,14.3 6.1,14.2 6,14.1 6.1,14 6.2,14 7,13.9 9.5,13.7 9.8,15.7 c 0,0.1 0,0.2 -0.2,0.3 z" />
      <path d="m 9.3,17.8 c -0.2,0 -0.5,0 -0.7,-0.1 C 7.4,17.5 6.4,16.8 6.1,16.3 6,16.2 6.1,16.1 6.2,16 c 0.1,-0.1 0.2,0 0.3,0.1 0.3,0.5 1.2,1.1 2.2,1.2 0.6,0.1 1.6,0.1 2.4,-0.6 0.1,-0.1 0.2,-0.1 0.3,0 0.1,0.1 0.1,0.2 0,0.3 -0.7,0.6 -1.4,0.8 -2.1,0.8 z" />
      <path d="m 12.5,18 c -0.8,0 -1.5,-0.2 -1.8,-0.5 -0.1,-0.1 -0.1,-0.2 0,-0.3 0.1,-0.1 0.2,-0.1 0.3,0 0.4,0.4 1.5,0.5 2.3,0.3 0.8,-0.1 1.3,-0.5 1.6,-1 0.1,-0.1 0.2,-0.1 0.3,-0.1 0.1,0.1 0.1,0.2 0.1,0.3 -0.3,0.6 -1,1 -1.9,1.2 C 13.1,18 12.8,18 12.5,18 Z" />
      <path d="M 13.1,16.1 C 13,16.1 13,16.1 12.9,16 12,14.4 10.1,15.2 9.5,15.4 c -0.1,0.1 -0.2,0 -0.3,-0.1 -0.1,-0.1 0,-0.2 0.1,-0.3 0.5,-0.3 1.3,-0.6 2.1,-0.5 0.8,0.1 1.4,0.5 1.8,1.2 0.2,0.2 0.1,0.3 -0.1,0.4 0.1,0 0.1,0 0,0 z" />
      <path d="m 12.9,15.5 c -0.1,0 -0.1,0 -0.2,-0.1 -0.1,-0.1 -0.1,-0.2 0,-0.3 0.6,-0.5 1.8,-1 2.8,-0.8 0.5,0.1 0.9,0.4 1.1,0.9 0,0.1 0,0.2 -0.1,0.3 -0.1,0 -0.2,0 -0.3,-0.1 -0.1,-0.3 -0.4,-0.5 -0.8,-0.6 -0.8,-0.2 -1.9,0.2 -2.4,0.7 0,0 0,0 -0.1,0 z" />
      <path d="m 16.3,15 h -0.1 c -0.1,-0.1 -0.1,-0.2 -0.1,-0.3 0.3,-0.6 1.3,-1.3 2.3,-1.5 0.6,-0.1 1.2,0.1 1.6,0.5 0.1,0.1 0.1,0.2 0,0.3 -0.1,0.1 -0.2,0.1 -0.3,0 -0.3,-0.3 -0.7,-0.4 -1.2,-0.4 -0.8,0.1 -1.7,0.7 -1.9,1.2 -0.2,0.1 -0.3,0.2 -0.3,0.2 z" />
      <path d="m 19.5,13.8 v 0 c -0.2,0 -0.2,-0.1 -0.2,-0.3 0.1,-0.5 0.4,-0.9 0.9,-1.1 0.7,-0.4 1.5,-0.4 2.4,0 0.1,0 0.2,0.2 0.1,0.3 0,0.1 -0.2,0.2 -0.3,0.1 -0.8,-0.3 -1.5,-0.3 -2.1,0 -0.4,0.2 -0.6,0.5 -0.7,0.9 0.1,0.1 0,0.1 -0.1,0.1 z" />
      <path d="m 16,17.7 c -0.5,0 -1,-0.1 -1.6,-0.3 -0.1,0 -0.2,-0.2 -0.1,-0.3 0,-0.1 0.2,-0.2 0.3,-0.1 1.9,0.6 3.3,0.1 4.1,-1.5 0.1,-0.1 0.2,-0.1 0.3,-0.1 0.1,0.1 0.1,0.2 0.1,0.3 -0.5,1 -1.4,2 -3.1,2 z" />
      <path d="m 18.5,16.5 v 0 c -0.2,0 -0.3,-0.1 -0.3,-0.2 0,-0.1 0.1,-0.2 0.2,-0.2 v 0 0 c 1.3,0 2.7,-0.9 2.9,-2.3 0,-0.1 0.1,-0.2 0.2,-0.2 0.1,0 0.2,0.1 0.2,0.2 -0.1,1.7 -1.7,2.7 -3.2,2.7 z" />
      <path d="m 1.4,11.4 v 0 C 1.3,11.4 1.2,11.3 1.2,11.2 1.2,10.8 1.4,10.5 1.7,10.3 2.3,9.9 3.3,9.9 4,10 c 0.1,0 0.2,0.1 0.2,0.2 0,0.1 -0.1,0.2 -0.2,0.2 -0.8,-0.2 -1.5,-0.1 -1.9,0.2 -0.2,0.1 -0.3,0.3 -0.3,0.6 -0.2,0.1 -0.3,0.2 -0.4,0.2 z" />
      <path d="m 5.9,11.8 c 0,0 -0.1,0 0,0 C 5.7,11.7 5.7,11.6 5.7,11.5 6.1,10.8 6.8,9.9 7.6,9.1 8.2,8.5 9.2,7.8 10,7.9 10.1,7.9 10.2,8 10.2,8.1 10.2,8.2 10.1,8.3 10,8.3 8.8,8.1 6.8,10.4 6.1,11.7 6,11.8 6,11.8 5.9,11.8 Z" />
      <path d="M 10.1,9.7 C 10,9.7 10,9.7 9.9,9.6 9.8,9.5 9.9,9.4 10,9.3 10.2,9.2 10.4,9 10.7,8.8 c 0.8,-0.6 1.7,-1.2 2.6,-1.4 1.4,-0.3 2.8,0 3.8,0.8 0.7,0.1 1,0.3 1.7,0.6 0.1,0.1 0.1,0.2 0.1,0.3 C 18.8,9.2 18.7,9.2 18.6,9.2 18,8.8 17.7,8.7 17,8.6 H 16.9 C 16,7.8 14.7,7.6 13.4,7.9 12.6,8.1 11.8,8.7 11,9.3 10.8,9.5 10.6,9.6 10.3,9.8 10.2,9.7 10.2,9.7 10.1,9.7 Z" />
      <path d="m 5.5,13.5 v 0 C 5.3,13.4 5.3,13.3 5.3,13.2 c 0.6,-1.1 1.7,-2 2.8,-2.4 0.1,0 0.2,0 0.3,0.1 0,0.1 0,0.2 -0.1,0.3 -1,0.4 -2,1.2 -2.6,2.2 -0.1,0 -0.1,0.1 -0.2,0.1 z" />
      <path d="m 12,10.7 c -0.1,0 -0.1,0 -0.2,-0.1 -0.1,-0.1 -0.1,-0.2 0,-0.3 1.4,-1.1 3.6,-2.3 5.1,-2.1 0.1,0 0.2,0.1 0.2,0.2 0,0.1 -0.1,0.2 -0.2,0.2 -1.5,-0.1 -3.5,1 -4.9,2.1 0.1,0 0,0 0,0 z" />
      <path d="m 7.3,14.4 c 0,0 -0.1,-0.1 0,0 C 7.1,14.3 7,14.2 7.1,14 7.5,13.1 8.9,11.8 10,11.5 c 0.1,0 0.2,0 0.3,0.1 0,0.1 0,0.2 -0.1,0.3 -1,0.3 -2.3,1.5 -2.7,2.3 -0.1,0.1 -0.1,0.2 -0.2,0.2 z" />
      <path d="M 19.8,13 C 19.7,13 19.7,13 19.6,12.9 18.3,11.3 16.2,9.8 14.8,9.1 14.7,9 14.7,8.9 14.7,8.8 14.8,8.7 14.9,8.7 15,8.7 c 1.5,0.8 3.7,2.3 5,3.9 0.1,0.1 0.1,0.2 0,0.3 C 19.9,13 19.9,13 19.8,13 Z" />
      <path d="m 17.8,13.7 c -0.1,0 -0.1,0 -0.2,-0.1 -1.1,-1.4 -3,-2.9 -4.7,-3.6 -0.1,0 -0.2,-0.2 -0.1,-0.3 0,-0.1 0.2,-0.2 0.3,-0.1 1.8,0.8 3.8,2.4 4.9,3.8 0.1,0.1 0.1,0.2 0,0.3 -0.1,0 -0.2,0 -0.2,0 z" />
      <path d="m 11.2,8.9 c -0.1,0 -0.1,0 0,0 C 10.5,8.6 10.2,8.4 9.6,8.2 9.4,8.2 9.4,8 9.4,7.9 9.4,7.8 9.6,7.7 9.7,7.8 c 0.6,0.3 1,0.4 1.6,0.7 0.1,0.1 0.1,0.2 0.1,0.3 -0.1,0.1 -0.2,0.1 -0.2,0.1 z" />
      <path d="m 16.6,14.5 c -0.1,0 -0.1,0 -0.2,-0.1 -0.3,-0.5 -1.1,-1.3 -2,-1.8 -0.1,-0.1 -0.1,-0.2 -0.1,-0.3 0.1,-0.1 0.2,-0.1 0.3,-0.1 1,0.6 1.9,1.4 2.2,1.9 0.1,0.1 0,0.2 -0.1,0.3 -0.1,0.1 -0.1,0.1 -0.1,0.1 z" />
      <path d="m 12.8,11.4 c 0,0 -0.1,0 -0.1,-0.1 C 11.3,10.1 9.9,9.5 8.1,9.1 7.9,9.2 7.9,9.1 7.9,9 7.9,8.9 8,8.8 8.1,8.8 c 1.9,0.3 3.3,1 4.8,2.2 0.1,0.1 0.1,0.2 0,0.3 0,0.1 -0.1,0.1 -0.1,0.1 z" />
      <path d="m 14.3,14.8 c -0.1,0 -0.1,0 -0.2,-0.1 -0.3,-0.4 -0.8,-0.8 -1.3,-1 -0.1,0 -0.2,-0.2 -0.1,-0.3 0,-0.1 0.2,-0.2 0.3,-0.1 0.5,0.2 1.1,0.7 1.4,1.1 0.1,0.1 0.1,0.2 0,0.3 0,0.1 -0.1,0.1 -0.1,0.1 z" />
      <path d="M 11.1,12.6 H 11 C 9.6,11.5 8.5,11 6.6,10.7 c -0.1,0 -0.2,-0.1 -0.2,-0.2 0,-0.1 0.1,-0.2 0.2,-0.2 1.9,0.3 3.1,0.9 4.6,2 0.1,0.1 0.1,0.2 -0.1,0.3 0.1,0 0.1,0 0,0 z" />
      <path d="m 12.7,15.4 c -0.1,0 -0.1,0 -0.1,-0.1 -1,-0.9 -2.4,-2 -4.3,-2.3 -0.1,0 -0.2,-0.1 -0.2,-0.2 0,-0.1 0.1,-0.2 0.2,-0.2 2,0.4 3.5,1.6 4.5,2.4 0.1,0.1 0.1,0.2 -0.1,0.4 0.2,0 0.1,0 0,0 z" />
      <path d="m 6.4,12.2 v 0 C 5.7,11.7 5,11.4 4.3,11.4 4.2,11.4 4.1,11.3 4.1,11.2 4.1,11.1 4.2,11 4.3,11 c 0.8,0 1.6,0.4 2.3,0.9 0.1,0.1 0.1,0.2 0,0.3 -0.1,-0.1 -0.2,0 -0.2,0 z" />
      <path d="m 22,12.6 c -0.1,0 -0.1,0 -0.2,-0.1 -0.3,-0.5 -0.6,-1 -1.1,-1.5 v 0 c -1,-1.5 -1.8,-2.3 -4.1,-1.1 -0.1,0.1 -0.2,0 -0.3,-0.1 -0.1,-0.1 0,-0.2 0.1,-0.3 2.6,-1.4 3.6,-0.4 4.7,1.2 0.5,0.5 0.9,1 1.2,1.6 -0.1,0.1 -0.1,0.3 -0.3,0.3 0.1,0 0,0 0,0 z" />
      <path d="m 10.3,13.6 h -0.1 c -0.1,-0.1 -0.1,-0.2 0,-0.3 1,-1.4 2.8,-2.5 4.3,-2.7 0.1,0 0.2,0.1 0.2,0.2 0,0.1 -0.1,0.2 -0.2,0.2 -1.4,0.2 -3.1,1.3 -4,2.6 -0.1,0 -0.1,0 -0.2,0 z" />
      <path d="m 18.1,11.2 c -0.1,0 -0.2,0 -0.2,-0.1 -0.1,-0.1 0,-0.2 0.1,-0.3 1.1,-0.5 2.1,-0.3 3,-0.1 0.1,0 0.2,0.1 0.1,0.3 0,0.1 -0.1,0.2 -0.3,0.1 -0.8,-0.2 -1.7,-0.4 -2.7,0.1 z" />
      <path d="m 12,14.6 c -0.1,0 -0.1,0 -0.1,-0.1 -0.1,-0.1 -0.1,-0.2 0,-0.3 1.4,-1.6 2.7,-2.4 4.2,-2.6 0.1,0 0.2,0.1 0.2,0.2 0,0.1 -0.1,0.2 -0.2,0.2 -1.4,0.2 -2.6,1 -3.9,2.5 -0.1,0.1 -0.2,0.1 -0.2,0.1 z" />
      <path d="m 2.9,10.2 c -0.1,0 -0.1,0 -0.1,-0.1 C 2.7,10 2.7,9.9 2.8,9.8 3.6,9 4.6,8.7 5.7,8.7 5.8,8.7 5.9,8.8 5.9,8.9 5.9,9 5.8,9.1 5.7,9.1 4.7,9 3.8,9.4 3.1,10 3,10.2 2.9,10.2 2.9,10.2 Z" />
      <path d="m 21.4,14.8 c -0.1,0 -0.2,-0.1 -0.2,-0.1 0,-0.1 0,-0.2 0.1,-0.3 1.8,-0.5 2.9,-1.4 2.9,-2.3 0,-0.2 -0.1,-0.5 -0.4,-0.6 -0.3,-0.2 -0.8,-0.2 -1.3,0 -0.1,0.1 -0.2,0 -0.3,-0.1 -0.1,-0.1 0,-0.2 0.1,-0.3 0.6,-0.3 1.2,-0.3 1.7,0 0.4,0.2 0.6,0.6 0.6,1 0,1.1 -1.2,2.1 -3.2,2.7 z" />
      <path d="m 23.6,11.4 c -0.1,0 -0.2,-0.1 -0.2,-0.2 -0.1,-0.4 -0.4,-0.7 -0.7,-0.8 -0.4,-0.2 -1,-0.3 -1.3,-0.2 -0.1,0 -0.2,0 -0.3,-0.1 0,-0.1 0,-0.2 0.1,-0.3 0.4,-0.1 1,-0.1 1.6,0.2 0.5,0.2 0.8,0.6 0.9,1.1 0.2,0.1 0.1,0.2 -0.1,0.3 0.1,0 0.1,0 0,0 z" />
      <path d="M 22.1,10.1 C 22,10.1 22,10.1 22,10 21.3,9.3 20.2,9 18.8,9.3 c -0.1,0 -0.2,-0.1 -0.2,-0.2 0,-0.1 0.1,-0.2 0.2,-0.2 1.5,-0.2 2.8,0.1 3.5,0.9 0.1,0.1 0.1,0.2 0,0.3 -0.1,0 -0.1,0 -0.2,0 z" />
    </g>
  </svg>
);

export default Lattice;
