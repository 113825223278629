import React from 'react';

const Pie = () => (
  <svg viewBox="0 0 25 15">
    <g transform="translate(0,-6.135513)">
      <path d="M 12.5,20 C 9,20 6,19.1 4.7,17.7 v 0 L 2.6,15.2 c -0.1,-0.1 -0.1,-0.2 0,-0.3 0.1,-0.1 0.2,-0.1 0.3,0 L 5,17.4 c 1.3,1.4 4.1,2.2 7.5,2.2 3.3,0 6.4,-0.9 7.5,-2.2 l 2.7,-3.6 c 0.1,-0.1 0.2,-0.1 0.3,0 0.1,0.1 0.1,0.2 0,0.3 l -2.7,3.6 v 0 C 19.1,19.1 15.9,20 12.5,20 Z" />
      <path d="m 3.1,12.9 v 0 C 2.9,12.8 2.9,12.7 2.9,12.6 3.2,11.9 3.5,11.4 4,11 4.9,9.6 5.4,8.9 6.2,8.4 7.4,7.6 9.1984169,7.5929259 10.504488,7.3823274 c 1.927833,0.027051 1.922464,0.066211 2.422464,0.2662112 C 11.906078,7.5404245 11.082742,7.635117 9.8808627,7.7833159 8.4406717,7.9829381 7.2809004,8.13376 6.4,8.7 5.7,9.2 5.2,9.8 4.3,11.2 v 0 c -0.5,0.4 -0.8,0.9 -1,1.5 0,0.1 -0.1,0.2 -0.2,0.2 z" />
      <path d="m 3.6,14.9 c -0.1,0 -0.1,0 0,0 -1,-0.3 -1.8,-0.7 -2.4,-1.2 -0.5,-0.5 -0.8,-1.1 -0.8,-1.6 0,-0.4 0.2,-0.7 0.6,-1 0.5,-0.3 1.1,-0.3 1.7,0 0.1,0.1 0.1,0.2 0.1,0.3 -0.1,0.1 -0.2,0.1 -0.3,0.1 -0.4,-0.2 -0.9,-0.3 -1.3,0 -0.2,0.1 -0.4,0.3 -0.4,0.6 0,0.8 0.9,1.8 2.8,2.4 0.1,0 0.2,0.2 0,0.4 0.1,0 0,0 0,0 z" />
      <path d="m 6,15.2 v 0 C 5.8,15.1 5.8,15 5.8,14.9 6,14.5 6,14.1 5.8,13.8 5.2,13 3.6,12.8 2.7,12.9 c -0.1,0 -0.2,-0.1 -0.2,-0.2 0,-0.1 0.1,-0.2 0.2,-0.2 1,-0.1 2.8,0.2 3.5,1.1 0.2,0.4 0.2,0.9 0,1.4 0,0.1 -0.1,0.2 -0.2,0.2 z" />
      <path d="m 6,16.4 c -2.1,0 -3.7,-1 -4.1,-2.2 0,-0.1 0,-0.2 0.1,-0.3 0.1,0 0.2,0 0.3,0.1 0.2,0.7 0.9,1.3 1.9,1.6 1.1,0.4 2.4,0.4 3.7,0 0.1,0 0.2,0 0.3,0.1 0,0.1 0,0.2 -0.1,0.3 -0.8,0.3 -1.5,0.4 -2.1,0.4 z" />
      <path d="M 9.6,16 C 9.5,16 9.4,15.9 9.4,15.8 9.2,14.7 8.2,14.3 6.3,14.4 6.2,14.4 6.1,14.3 6.1,14.2 6,14.1 6.1,14 6.2,14 7,13.9 9.5,13.7 9.8,15.7 c 0,0.1 0,0.2 -0.2,0.3 z" />
      <path d="m 9.3,17.8 c -0.2,0 -0.5,0 -0.7,-0.1 C 7.4,17.5 6.4,16.8 6.1,16.3 6,16.2 6.1,16.1 6.2,16 c 0.1,-0.1 0.2,0 0.3,0.1 0.3,0.5 1.2,1.1 2.2,1.2 0.6,0.1 1.6,0.1 2.4,-0.6 0.1,-0.1 0.2,-0.1 0.3,0 0.1,0.1 0.1,0.2 0,0.3 -0.7,0.6 -1.4,0.8 -2.1,0.8 z" />
      <path d="m 12.5,18 c -0.8,0 -1.5,-0.2 -1.8,-0.5 -0.1,-0.1 -0.1,-0.2 0,-0.3 0.1,-0.1 0.2,-0.1 0.3,0 0.4,0.4 1.5,0.5 2.3,0.3 0.8,-0.1 1.3,-0.5 1.6,-1 0.1,-0.1 0.2,-0.1 0.3,-0.1 0.1,0.1 0.1,0.2 0.1,0.3 -0.3,0.6 -1,1 -1.9,1.2 C 13.1,18 12.8,18 12.5,18 Z" />
      <path d="M 13.1,16.1 C 13,16.1 13,16.1 12.9,16 12,14.4 10.1,15.2 9.5,15.4 c -0.1,0.1 -0.2,0 -0.3,-0.1 -0.1,-0.1 0,-0.2 0.1,-0.3 0.5,-0.3 1.3,-0.6 2.1,-0.5 0.8,0.1 1.4,0.5 1.8,1.2 0.2,0.2 0.1,0.3 -0.1,0.4 0.1,0 0.1,0 0,0 z" />
      <path d="m 12.9,15.5 c -0.1,0 -0.1,0 -0.2,-0.1 -0.1,-0.1 -0.1,-0.2 0,-0.3 0.6,-0.5 1.8,-1 2.8,-0.8 0.5,0.1 0.9,0.4 1.1,0.9 0,0.1 0,0.2 -0.1,0.3 -0.1,0 -0.2,0 -0.3,-0.1 -0.1,-0.3 -0.4,-0.5 -0.8,-0.6 -0.8,-0.2 -1.9,0.2 -2.4,0.7 0,0 0,0 -0.1,0 z" />
      <path d="m 16.3,15 h -0.1 c -0.1,-0.1 -0.1,-0.2 -0.1,-0.3 0.3,-0.6 1.3,-1.3 2.3,-1.5 0.6,-0.1 1.2,0.1 1.6,0.5 0.1,0.1 0.1,0.2 0,0.3 -0.1,0.1 -0.2,0.1 -0.3,0 -0.3,-0.3 -0.7,-0.4 -1.2,-0.4 -0.8,0.1 -1.7,0.7 -1.9,1.2 -0.2,0.1 -0.3,0.2 -0.3,0.2 z" />
      <path d="m 19.5,13.8 v 0 c -0.2,0 -0.2,-0.1 -0.2,-0.3 0.1,-0.5 0.4,-0.9 0.9,-1.1 0.7,-0.4 1.5,-0.4 2.4,0 0.1,0 0.2,0.2 0.1,0.3 0,0.1 -0.2,0.2 -0.3,0.1 -0.8,-0.3 -1.5,-0.3 -2.1,0 -0.4,0.2 -0.6,0.5 -0.7,0.9 0.1,0.1 0,0.1 -0.1,0.1 z" />
      <path d="m 16,17.7 c -0.5,0 -1,-0.1 -1.6,-0.3 -0.1,0 -0.2,-0.2 -0.1,-0.3 0,-0.1 0.2,-0.2 0.3,-0.1 1.9,0.6 3.3,0.1 4.1,-1.5 0.1,-0.1 0.2,-0.1 0.3,-0.1 0.1,0.1 0.1,0.2 0.1,0.3 -0.5,1 -1.4,2 -3.1,2 z" />
      <path d="m 18.5,16.5 v 0 c -0.2,0 -0.3,-0.1 -0.3,-0.2 0,-0.1 0.1,-0.2 0.2,-0.2 v 0 0 c 1.3,0 2.7,-0.9 2.9,-2.3 0,-0.1 0.1,-0.2 0.2,-0.2 0.1,0 0.2,0.1 0.2,0.2 -0.1,1.7 -1.7,2.7 -3.2,2.7 z" />
      <path d="m 1.4,11.4 v 0 C 1.3,11.4 1.2,11.3 1.2,11.2 1.2,10.8 1.4,10.5 1.7,10.3 2.3,9.9 3.3,9.9 4,10 c 0.1,0 0.2,0.1 0.2,0.2 0,0.1 -0.1,0.2 -0.2,0.2 -0.8,-0.2 -1.5,-0.1 -1.9,0.2 -0.2,0.1 -0.3,0.3 -0.3,0.6 -0.2,0.1 -0.3,0.2 -0.4,0.2 z" />
      <path d="M 8.9778794,8.2542863 C 9.4859471,7.822045 10.314794,7.76161 11.014794,7.86161 c 0.1,0 0.2,0.1 0.2,0.2 0,0.1 -0.1,0.2 -0.2,0.2 -0.8,-0.2 -1.4490448,-0.1681332 -1.8825746,0.090062 0,0 -0.4461599,0.3378777 -0.15434,-0.097386 z" />
      <path d="M 10.504488,7.3823274 C 11.904488,7.0823274 16.1,7.4 17.1,8.2 c 0.7,0.1 1,0.3 1.7,0.6 0.1,0.1 0.1,0.2 0.1,0.3 C 18.8,9.2 18.7,9.2 18.6,9.2 18,8.8 17.7,8.7 17,8.6 L 16.565249,8.4097655 C 15.464521,7.8165242 14.863858,7.9583609 13.405492,7.7183697 11.830727,7.4661437 9.2118274,7.7875798 10.504488,7.3823274 Z" />
      <path d="m 8.2804961,11.616823 c 0,0 -0.1,-0.1 0,0 -0.2,-0.1 -0.3,-0.2 -0.2,-0.4 0.4,-0.9 1.7999998,-2.2000009 2.8999999,-2.5000009 0.1,0 0.2,0 0.3,0.1 0,0.1 0,0.2 -0.1,0.2999996 -1,0.300001 -2.2999999,1.5000013 -2.6999999,2.3000013 -0.1,0.1 -0.1,0.2 -0.2,0.2 z" />
      <path d="m 14.862078,11.174173 c -0.1,0 -0.1,0 -0.2,-0.1 -0.3,-0.5 -1.1,-1.2999996 -2,-1.7999996 -0.1,-0.1 -0.1,-0.2 -0.1,-0.3 0.1,-0.1 0.2,-0.1 0.3,-0.1 1,0.6 1.9,1.3999996 2.2,1.8999996 0.1,0.1 0,0.2 -0.1,0.3 -0.1,0.1 -0.1,0.1 -0.1,0.1 z" />
      <path d="m 22,12.6 c -0.1,0 -0.1,0 -0.2,-0.1 C 21.5,12 20.7,11 20.7,11 v 0 C 19.793548,9.5926998 19.808158,9.8822333 18.6,9.2 c 0.8,-0.1 1.215029,-0.3423914 2.5,1.5 0.5,0.5 0.9,1 1.2,1.6 -0.1,0.1 -0.1,0.3 -0.3,0.3 0.1,0 0,0 0,0 z" />
      <path d="m 13.991088,7.8124568 c 0,0 -0.856618,0.078234 -1.222594,0.4523296 -0.1,0.1 -0.2,0 -0.3,-0.1000001 -0.1,-0.1 0,-0.2 0.1,-0.3 1.020809,-0.3787687 1.028303,-0.2667508 1.305948,-0.2485698 0.424589,0.017834 0.508513,0.1737983 0.116646,0.1962403 z" />
      <path d="m 2.9,10.2 c -0.1,0 -0.1,0 -0.1,-0.1 C 2.7,10 2.7,9.9 2.8,9.8 3.6,9 4.6,8.7 5.7,8.7 5.8,8.7 5.9,8.8 5.9,8.9 5.9,9 5.8,9.1 5.7,9.1 4.7,9 3.8,9.4 3.1,10 3,10.2 2.9,10.2 2.9,10.2 Z" />
      <path d="m 21.4,14.8 c -0.1,0 -0.2,-0.1 -0.2,-0.1 0,-0.1 0,-0.2 0.1,-0.3 1.8,-0.5 2.9,-1.4 2.9,-2.3 0,-0.2 -0.1,-0.5 -0.4,-0.6 -0.3,-0.2 -0.8,-0.2 -1.3,0 -0.1,0.1 -0.2,0 -0.3,-0.1 -0.1,-0.1 0,-0.2 0.1,-0.3 0.6,-0.3 1.2,-0.3 1.7,0 0.4,0.2 0.6,0.6 0.6,1 0,1.1 -1.2,2.1 -3.2,2.7 z" />
      <path d="m 23.6,11.4 c -0.1,0 -0.2,-0.1 -0.2,-0.2 -0.1,-0.4 -0.4,-0.7 -0.7,-0.8 -0.4,-0.2 -1,-0.3 -1.3,-0.2 -0.1,0 -0.2,0 -0.3,-0.1 0,-0.1 0,-0.2 0.1,-0.3 0.4,-0.1 1,-0.1 1.6,0.2 0.5,0.2 0.8,0.6 0.9,1.1 0.2,0.1 0.1,0.2 -0.1,0.3 0.1,0 0.1,0 0,0 z" />
      <path d="M 22.1,10.1 C 22,10.1 22,10.1 22,10 21.3,9.3 20.2,9 18.8,9.3 c -0.1,0 -0.2,-0.1 -0.2,-0.2 0,-0.1 0.1,-0.2 0.2,-0.2 1.5,-0.2 2.8,0.1 3.5,0.9 0.1,0.1 0.1,0.2 0,0.3 -0.1,0 -0.1,0 -0.2,0 z" />
    </g>
  </svg>
);

export default Pie;
