import React from 'react';
import Corner from './imgs/corner';
import Side from './imgs/side';

interface BorderProps {
  children: React.ReactNode;
  width?: number;
}

const Border = ({ children, width = 48 }: BorderProps) => (
  <div className="flex flex-col w-full justify-between">
    {/* Top Row */}
    <div className="flex flex-row justify-between">
      <Corner size={ width } rotation={0} />
      <Side size={width} />
      <Corner size={ width } rotation={90} />
    </div>

    {/* Center Row Content */}
    <div className="flex flex-row h-full justify-between">
      <div><Side size={width} isVertical={true} /></div>
      {children}
      <div><Side size={width} isVertical={true} /></div>
    </div>

    {/* Bottom Row */}
    <div className="flex flex-row justify-between">
      <Corner size={ width } rotation={270} />
      <Side size={width} />
      <Corner size={ width } rotation={180} />
    </div>
  </div>
);

export default Border;
