import React from 'react';
import { Helmet } from 'react-helmet';
import winnie from '../assets/winfinity.gif';

const Winfinity = () => (
  <div className="flex w-full h-screen justify-center items-center p-1 bg-black">
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    </Helmet>
    <div className="flex h-min max-w-3xl" >
      <img src={winnie} style={{ aspectRatio: 1 }} />
    </div>
  </div>
);

export default Winfinity;
